import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { DeviceContainerStates } from "./DeviceContainer";
import { ContainerAttributes } from "./Container";

@AttributeGroup({ title: 'DeviceFlex', blockName: 'DeviceFlex', icon: 'responsive' })
export class DeviceFlexAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '', disable: ['display', 'flex', 'flexBasis', 'listStyle', 'zIndex', 'textAlign', 'position', 'flexGrow', 'padding', 'margin'] })
    container: DeviceContainerStates = new DeviceContainerStates().fromJSON({
        desktop: new ContainerAttributes().fromJSON({
            display: 'flex',
            width: '100%'
        }),
        mobile: new ContainerAttributes().fromJSON({
            display: 'flex',
            width: '100%'
        }),
    })
}

const DeviceFlexTheme = styled.div((props: { attributes: DeviceFlexAttributes }) => `

    ${props.attributes.container.desktop.toCSS()}

    @media(max-width:800px){
        
        ${props.attributes.container.mobile.toCSS()}
    }

`);

function DeviceFlex(props: ComponentProps<DeviceFlexAttributes>) {
    return (
        <DeviceFlexTheme attributes={props.attributes}>
            {props.children}
        </DeviceFlexTheme>
    )
}


DeviceFlex.prototype.Props = DeviceFlexAttributes;


export default DeviceFlex;