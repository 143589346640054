import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ButtonStateAttributes } from "./LinkButton";
import ActionButton, { ActionButtonAttributes } from "./ActionButton";
import { useContext } from "react";
import { ConfigContext } from "../providers/Content";

@AttributeGroup({ title: 'SwitchActionButton', blockName: 'SwitchActionButton' })
export class SwitchActionButtonAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', exportCssPropertyToWrapper: true, cssTarget: '' })
    onTheme: ButtonStateAttributes = new ButtonStateAttributes();

    @AttributeProperty({ type: 'Object', exportCssPropertyToWrapper: true, cssTarget: '' })
    OffTheme: ButtonStateAttributes = new ButtonStateAttributes();

    @AttributeProperty({ type: 'String' })
    state: string = '';

    @AttributeProperty({ type: 'String' })
    onAction: string = '';

    @AttributeProperty({ type: 'String' })
    offAction: string = '';
}

// const SwitchActionButtonTheme = styled.div((props: { attributes: SwitchActionButtonAttributes }) => `

//     ${props.attributes.toCSS()}

// `);

function SwitchActionButton(props: ComponentProps<SwitchActionButtonAttributes>) {

    const content = useContext(ConfigContext);
    const state = props.attributes.state ? content.getContent(props.attributes.state) : false;

    const buttonAttrs = new ActionButtonAttributes().fromJSON({
        buttonTheme: state ? props.attributes.onTheme : props.attributes.OffTheme,
        action: state ? props.attributes.onAction : props.attributes.offAction,
    });

    return (
        <ActionButton {...props} attributes={buttonAttrs} >
            {props.children}
        </ActionButton>
    )
}


SwitchActionButton.prototype.Props = SwitchActionButtonAttributes;


export default SwitchActionButton;