import React, { useEffect, useState } from "react"

export type PromiseLoaderProps<P> = {
    promise?: Promise<any>
    children: (res?: P) => React.ReactElement | undefined
    loader: React.ReactElement,
    buildError?: (e: any) => React.ReactElement,
    overlayColor?: string
    hideChildrenWhileLoading?: boolean
}

export interface IPromiseLoaderContext {
    isLoading: boolean
}
export const PromiseLoaderContext = React.createContext<IPromiseLoaderContext>({ isLoading: false });

/**
 * 
 * @param props 
 * @returns 
 */
export const PromiseLoader = <P = any,>(props: PromiseLoaderProps<P>) => {

    const [loading, setLoading] = useState<boolean>(props.promise ? true : false);
    // const [loader, setLoader] = useState();
    const [response, setResponse] = useState<P>();
    const [error, setError] = useState<any>();

    useEffect(() => {

        if (props.promise) {

            setError(undefined);
            setLoading(true);
            setResponse(undefined);

            // toggle loading off - when resolved
            props.promise
                ?.then((r) => setResponse(r))
                .catch((e) => setError(e))
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.promise]);

    return (
        <PromiseLoaderContext.Provider value={{ isLoading: loading }} >
            {error && props.buildError && props.buildError(error)}
            {response ? props.children(response) : (!props.hideChildrenWhileLoading && props.children())}
            {loading && props.loader && <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: props.overlayColor || 'transparent' }}>{props.loader}</div>}
        </PromiseLoaderContext.Provider>
    )
}