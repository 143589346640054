import { Attribute, AttributeProperty } from "../widgets/BaseComponent";

export class PositionAttributes extends Attribute {

    @AttributeProperty({ type: 'Select', selectOptions: ['absolute', 'relative', 'fixed', 'sticky'], cssProperty: 'position' })
    position?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'top' })
    top?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'bottom' })
    bottom?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'left' })
    left?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'left' })
    right?: string;
}