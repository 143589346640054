import { useContext, useEffect, useState } from "react";
import { NavLink, matchPath } from "react-router-dom";
import styled from "styled-components";
import { Page } from "../attributes/Page";
import { useHash } from "../providers/useHash";
import { Attribute, AttributeGroup, AttributeGroupType, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ButtonAttributes, ButtonStateAttributes } from "./LinkButton";
import { ConfigContext } from "../providers/Content";
import { ContainerAttributes } from "./Container";
import { FontTheme } from "../attributes/FontTheme";

export class NavLinkStateAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '', exportCssPropertyToWrapper: true })
    public container: ContainerAttributes = new ContainerAttributes();
    @AttributeProperty({ type: 'Object', cssTarget: '' })
    public font: FontTheme = new FontTheme();
    @AttributeProperty({ type: 'Image' })
    icon?: string;
}

@AttributeGroup({ blockName: 'Link', type: AttributeGroupType.State })
export class LinkTheme extends Attribute {
    @AttributeProperty({ type: 'Object', cssTarget: '' })
    static: NavLinkStateAttributes = new NavLinkStateAttributes()
    @AttributeProperty({ type: 'Object', cssTarget: ':hover' })
    hover: NavLinkStateAttributes = new NavLinkStateAttributes()
    @AttributeProperty({ type: 'Object', cssTarget: ':active, &.active' })
    active: NavLinkStateAttributes = new NavLinkStateAttributes()
    @AttributeProperty({ type: 'Object', cssTarget: ':visited' })
    visited: NavLinkStateAttributes = new NavLinkStateAttributes()
}


@AttributeGroup({ title: 'NavLink', blockName: 'NavLink', icon: 'link' })
export class NavLinkAttributes extends Attribute {

    @AttributeProperty({ type: 'Object' })
    page: Page = new Page()

    @AttributeProperty({ type: 'Object', cssTarget: ' .nav-link' })
    navLink: LinkTheme = new LinkTheme();

    @AttributeProperty({ type: 'Image' })
    icon?: string;

    @AttributeProperty({ type: 'Boolean' })
    isButton: boolean = false;
}

const NavLinkTheme = styled.li((props: { attributes: NavLinkAttributes }) => `

margin: 0;

${props.attributes.toCSS()}

    & > a .icon{
        ${!props.attributes.navLink.static.icon ? `display: none;` : ''}
        background-repeat: no-repeat;
        background-image: url(${props.attributes.navLink.static.icon}) !important;
        background-size: contain !important;
        background-position: center;
    }
    &:hover{
        > a .icon{
            ${props.attributes.navLink.hover.icon && `background-image: url(${props.attributes.navLink.hover.icon}) !important;`}
        }
    }
    &.active{
        > a .icon{
            ${props.attributes.navLink.active.icon && `background-image: url(${props.attributes.navLink.active.icon}) !important;`}
        }
    }
    
position: relative;
list-style: none;
`);

function _NavLink(props: ComponentProps<NavLinkAttributes>) {

    const defaultState = !!matchPath(
        window.location.hash.replaceAll("#/", ""),
        props.attributes.page.path
    );

    const [isActive, setIsActive] = useState<boolean>(defaultState);
    const content = useContext(ConfigContext);

    const attributes = content.parseAttributes(props.attributes);

    return (
        <NavLinkTheme {...props} className={`${(props as any).className ?? ''} ${isActive ? 'active' : ''}`} >

            <NavLink {...(props.attributes.isButton ? { role: 'button' } : {})} className={(isActive) => {
                setIsActive(isActive);
                return isActive ? 'nav-link' : 'nav-link';
            }} to={'/' + attributes.page.path} >
                <div className="icon" ></div>
                {attributes.page.title}
            </NavLink>
            {props.children &&
                <div className={`sub-menu  `} >
                    {props.children}
                </div>
            }

        </NavLinkTheme>
    )
}


_NavLink.prototype.Props = NavLinkAttributes;


export default _NavLink;