import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "../BaseComponent";
import { Container, Row, Col, RowProps } from 'styled-bootstrap-grid';
import { media } from 'styled-bootstrap-grid';

export class BRowState extends Attribute {
    @AttributeProperty({ type: 'Select', selectOptions: ['start', 'end', 'center', 'baseline', 'stretch'], cssProperty: 'align-items' })
    alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
    @AttributeProperty({ type: 'Select', selectOptions: ['start', 'end', 'center', 'baseline', 'stretch', 'space-between', 'space-arround', 'space-evenly'], cssProperyBuilder: (c) => c && `justify-content:${c};` })
    justifyContent?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
    @AttributeProperty({ type: 'String', cssProperty: 'flex-wrap' })
    wrap?: string;
}

@AttributeGroup({ title: 'BRow', blockName: 'BRow', module: "layout" })
export class BRowAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', title: 'Default' })
    sm: BRowState = new BRowState();
    @AttributeProperty({ type: 'Object', title: 'Tablet' })
    md: BRowState = new BRowState();
    @AttributeProperty({ type: 'Object', title: 'Desktop' })
    lg: BRowState = new BRowState();
}

const BRowTheme = styled<RowProps & ComponentProps<BRowAttributes> & any>(Row)`

    
    margin: 0;

    ${(props: RowProps & ComponentProps<BRowAttributes> & any) => props.attributes.sm.toCSS()};

    ${media.md`
        ${(props: RowProps & ComponentProps<BRowAttributes> & any) => props.attributes.md.toCSS()};
    `}
    ${media.lg`
        ${(props: RowProps & ComponentProps<BRowAttributes> & any) => props.attributes.lg.toCSS()};
    `}
`;

function BRow(props: ComponentProps<BRowAttributes>) {
    return (
        <BRowTheme {...props} >
            {props.children}
        </BRowTheme>
    )
}


BRow.prototype.Props = BRowAttributes;


export default BRow;