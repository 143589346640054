import { useContext } from "react";
import styled from "styled-components";
import { ConfigContext } from "../providers/Content";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";

@AttributeGroup({ title: 'StateIcon', blockName: 'StateIcon' })
export class StateIconAttributes extends Attribute {

    @AttributeProperty({ type: 'String' })
    state: string = '';

    @AttributeProperty({ type: 'Image' })
    on?: string;

    @AttributeProperty({ type: 'Image' })
    off?: string;

    @AttributeProperty({ type: 'String' })
    width: string = '';

    @AttributeProperty({ type: 'String' })
    height: string = '';
}

const StateIconTheme = styled.div((props: { attributes: StateIconAttributes }) => `

    ${props.attributes.toCSS()}

    width: ${props.attributes.width};
    height: ${props.attributes.width};
    img {
        width: ${props.attributes.width};
        height: ${props.attributes.width};
    }

`);

function StateIcon(props: ComponentProps<StateIconAttributes>) {

    const content = useContext(ConfigContext);

    const state = props.attributes.state ? content.getContent(props.attributes.state) : false;
    // console.log('state', state);

    return (
        <StateIconTheme {...props}>
            <img loading="lazy" src={state ? props.attributes.on : props.attributes.off} />
        </StateIconTheme>
    )
}


StateIcon.prototype.Props = StateIconAttributes;


export default StateIcon;