import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ContainerAttributes } from "./Container";

@AttributeGroup({ title: 'Column', blockName: 'Column' })
export class ColumnAttributes extends Attribute {
    @AttributeProperty({ type: 'Object', title: 'Column', cssTarget: '', disable: ['display', 'flexDirection', 'flexWrap', 'flex', 'flexBasis', 'listStyle', 'zIndex', 'textAlign', 'position', 'flexGrow', 'padding', 'margin'] })
    container: ContainerAttributes = new ContainerAttributes().fromJSON({
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    })
}

const ColumnTheme = styled.div((props: { attributes: ColumnAttributes }) => `

    ${props.attributes.toCSS()}

`);

function Column(props: ComponentProps<ColumnAttributes>) {
    return (
        <ColumnTheme {...props}>
            {props.children}
        </ColumnTheme>
    )
}


Column.prototype.Props = ColumnAttributes;


export default Column;