

import styled from "styled-components";
import { FontTheme } from "../attributes/FontTheme";
import { Attribute, AttributeGroup, AttributeGroupType, ComponentProps, AttributeProperty } from "./BaseComponent";
import { ContainerAttributes } from "./Container";
import { ConfigContext, useContent } from "../providers/Content";
import { useContext } from "react";

export class ButtonAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '', exportCssPropertyToWrapper: true })
    public container: ContainerAttributes = new ContainerAttributes();
    @AttributeProperty({ type: 'Object', cssTarget: ' > a, & > p, & > label' })
    public font: FontTheme = new FontTheme();
}

@AttributeGroup({ blockName: 'Button', type: AttributeGroupType.State })
export class ButtonStateAttributes extends Attribute {
    @AttributeProperty({ type: 'Object', cssTarget: '', exportCssPropertyToWrapper: true })
    public static: ButtonAttributes = new ButtonAttributes()
    @AttributeProperty({ type: 'Object', cssTarget: ':hover', exportCssPropertyToWrapper: true })
    public hover: ButtonAttributes = new ButtonAttributes()
    @AttributeProperty({ type: 'Object', cssTarget: ':active, &.active', exportCssPropertyToWrapper: true })
    public active: ButtonAttributes = new ButtonAttributes()
}

const ButtonTheme = styled.button((props: { attributes: ButtonStateAttributes }) => `

    &.LinkButton{
        ${props.attributes.toCSS()}
    }
`);

@AttributeGroup({ blockName: 'LinkButton', title: 'Link Button' })
export class LinkButtonAttributes extends Attribute {
    @AttributeProperty({ type: 'Object', exportCssPropertyToWrapper: true })
    buttonTheme: ButtonStateAttributes = new ButtonStateAttributes();
    @AttributeProperty({ type: 'String' })
    buttonText: string = "Click me!";
    @AttributeProperty({ type: 'String' })
    link: string = "#";
    @AttributeProperty({ type: 'String' })
    action?: string;
}

const LinkButton = (props: ComponentProps<LinkButtonAttributes>) => {
    const content = useContext(ConfigContext);

    const doAction = () => {
        const func = props.attributes.action && content.getContent(props.attributes.action);
        return func && func();
    }
    return (
        <ButtonTheme {...props} attributes={props.attributes.buttonTheme} className={`LinkButton ${props.className}`} onClick={(e) => doAction()} ><a role="button" href={content.parseContent(props.attributes.link)} >{content.parseContent(props.attributes.buttonText)}</a></ButtonTheme>
    );
}

LinkButton.prototype.Props = LinkButtonAttributes;

export default LinkButton;