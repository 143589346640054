import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ContainerAttributes } from "./Container";

@AttributeGroup({ title: 'Box', blockName: 'Box' })
export class BoxAttributes extends Attribute {
    @AttributeProperty({ type: 'Object', cssTarget: '', disable: ['display', 'flexDirection', 'flexWrap', 'listStyle', 'zIndex', 'textAlign', 'position', 'gap', 'alignItems', 'justifyContent'] })
    container: ContainerAttributes = new ContainerAttributes().fromJSON({
        display: 'block',
        flex: '1',
        minHeight: '50px',
        minWidth: '50px',
    })
}

const BoxTheme = styled.div((props: { attributes: BoxAttributes }) => `

    ${props.attributes.toCSS()}

`);

function Box(props: ComponentProps<BoxAttributes>) {
    return (
        <BoxTheme {...props}>
            {props.children}
        </BoxTheme>
    )
}


Box.prototype.Props = BoxAttributes;


export default Box;