import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ButtonStateAttributes } from "./LinkButton";
import { useContext } from "react";
import { ConfigContext, IContentContext } from "../providers/Content";

@AttributeGroup({ title: 'ActionButton', blockName: 'ActionButton', icon: 'link-button' })
export class ActionButtonAttributes extends Attribute {
    @AttributeProperty({ type: 'Object', exportCssPropertyToWrapper: true, cssTarget: '.actionButton' })
    buttonTheme: ButtonStateAttributes = new ButtonStateAttributes();
    @AttributeProperty({ type: 'String' })
    action: string = '';
}

export const ActionButtonTheme = styled.button((props: { attributes: ActionButtonAttributes }) => `

    p {
        line-height: initial;
    }

    ${props.attributes.toCSS()}

`);

function ActionButton(props: ComponentProps<ActionButtonAttributes>) {

    const content = useContext(ConfigContext);
    const doAction = () => {
        const func = content.getContent(props.attributes.action);
        console.log(func);
        
        return func && func();
    }

    const onClick = (props as any).onClick;

    return (
        <ActionButtonTheme {...props} className={`actionButton ${(props as any).className}`}  onClick={(e) => onClick ? onClick(e) && doAction() : doAction()} attributes={props.attributes}>
            {props.children}
        </ActionButtonTheme>
    )
}


ActionButton.prototype.Props = ActionButtonAttributes;


export default ActionButton;