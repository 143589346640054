import { Attribute, AttributeProperty } from "../widgets/BaseComponent";

export class FontTheme extends Attribute {
    @AttributeProperty({ type: 'String', cssProperty: 'font-size' })
    size?: string;
    @AttributeProperty({ type: 'Select', selectOptions: ['normal', 'semibold', 'bold', 'bolder'], cssProperty: 'font-weight' })
    weight?: string;
    @AttributeProperty({ type: 'Select', selectOptions: ['left', 'right', 'center'], cssProperty: 'text-align' })
    align?: string;
    @AttributeProperty({ type: 'Select', selectOptions: ['none', 'capitalize', 'lowercase', 'uppercase'], cssProperty: 'text-transform' })
    transform?: string;
    @AttributeProperty({ type: 'String', cssProperty: 'text-decoration' })
    decoration?: string;
    @AttributeProperty({ type: 'Color', cssProperty: 'color' })
    color?: string;
    @AttributeProperty({ type: 'String', cssProperty: 'line-height' })
    lineHeight?: string;
    @AttributeProperty({ type: 'String', cssProperty: 'margin' })
    margin?: string;
    @AttributeProperty({ type: 'Color', cssProperty: 'background-color' })
    background?: string;
}