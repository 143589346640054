import React, { FC, ReactElement, useContext, useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { Attribute } from "../widgets/BaseComponent";

export class IContentContext {

    _dict: { [key: string]: any } = {};

    setContent(key: string, map: any): void {

    }
    getContent(key: string): any {
        return `${key.split(".").reduce((res, path) => {
            return res[path];
        }, this._dict)}`;
    }

    parseContent(str: string) {
        if (typeof str !== 'string') {
            return null;
        }

        return str.replace(/({\d})/g, (i) => {
            return this.getContent(i.replace(/{/, '').replace(/}/, ''));
        });
    }

    parseAttributes(attrs: any) {
        for (var key in attrs) {
            if (typeof attrs[key] == 'string')
                attrs[key] = this.parseContent(attrs[key]);
            else if (typeof attrs[key] == 'object')
                attrs[key] = this.parseAttributes(attrs[key]);
        }

        return attrs;
    }

}

export function parseContent(string: string, obj: any) {
    var s = string;
    for (var prop in obj) {
        s = s.replace(new RegExp('{' + prop + '}', 'g'), obj[prop]);
    }
    return s;
}

export const ContentContext = React.createContext<IContentContext>(new IContentContext());


export function useContent(attributes: Attribute) {

    const { getContent } = useContext(ContentContext);

}

export type ConfigContextState = {
    configs: any;
    getContent: (key: string) => any,
    parseContent: (str: string) => string,
    parseAttributes: (attrs: any) => any,
};

const contextDefaultValues: ConfigContextState = {
    configs: {},
    getContent: (s: string) => '',
    parseContent: (s: string) => '',
    parseAttributes: (attrs: any) => attrs,
};
type ConfigObject = {
    key: string
    value: any
};
export const ConfigContext = React.createContext<ConfigContextState>(
    contextDefaultValues
);
type ProviderPorps = {
    configJson: any,
    children: ReactElement
}
const ConfigsProvider: FC<ProviderPorps> = (props) => {

    const c = useContext(ConfigContext);
    const [configs, setConfigs] = useState<any>({ ...(c.configs || {}), ...props.configJson });
    const latestRequest = React.useRef({ ...(c.configs || {}), ...props.configJson });

    useEffect(() => {
        latestRequest.current = { ...(c.configs || {}), ...props.configJson };
        setConfigs(latestRequest.current);
    }, [props.configJson, c]);


    const getContent = (key: string): any => {
        return key?.replace(/{/, '')?.replace(/}/, '')?.split(".")?.reduce((res, path) => {
            return res && res[path];
        }, latestRequest.current) ?? '';
    }

    const parseContent = (str: string) => {
        if (typeof str !== 'string') {
            return '';
        }

        return str.replace(/({.+?})/g, (i) => {
            return getContent(i.replace(/{/, '').replace(/}/, ''));
        });
    }

    const parseAttributes = (attrs: any) => {
        var newAttrs: any = {};
        for (var key in attrs) {
            if (typeof attrs[key] == 'string')
                newAttrs[key] = parseContent(attrs[key]);
            else if (typeof attrs[key] == 'object')
                newAttrs[key] = parseAttributes(attrs[key]);
        }

        return newAttrs;
    }
    return (
        <ConfigContext.Provider value={{ configs, getContent, parseContent, parseAttributes }}>
            {props.children}
        </ConfigContext.Provider>
    );
};
export default ConfigsProvider;