import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { PromiseLoaderContext } from "../components/PromiseLoader";

@AttributeGroup({ title: 'Loader', blockName: 'Loader' })
export class LoaderAttributes extends Attribute {

}

const LoaderTheme = styled.div((props: { attributes: LoaderAttributes }) => `

    ${props.attributes.toCSS()}

`);

function Loader(props: ComponentProps<LoaderAttributes>) {
    return (
        <PromiseLoaderContext.Consumer>
            {value =>
                <div style={{ display: value.isLoading ? 'block' : 'none' }}>
                    {props.children}
                </div>
            }
        </PromiseLoaderContext.Consumer>
    )
}


Loader.prototype.Props = LoaderAttributes;


export default Loader;