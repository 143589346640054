import { Attribute, AttributeProperty } from "../widgets/BaseComponent";

export class BackgroundAttributes extends Attribute {
    @AttributeProperty({ type: 'Color', input: 'color', cssProperty: "background-color" })
    color?: string;

    @AttributeProperty({ type: 'Image', cssProperty: 'background-image', cssProperyBuilder: (val: string) => `background-image: url(${val})` })
    image?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'background-position' })
    position?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'background-size' })
    size?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'background-repeat' })
    repeat?: string;
}