import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ContainerAttributes } from "./Container";

@AttributeGroup({ title: 'Flex', blockName: 'Flex' })
export class FlexAttributes extends Attribute {
    @AttributeProperty({ type: 'Object', title: 'Flex', cssTarget: '', disable: ['display', 'flex', 'flexBasis', 'listStyle', 'zIndex', 'textAlign', 'position', 'flexGrow', 'padding'] })
    container: ContainerAttributes = new ContainerAttributes().fromJSON({
        display: 'flex',
        width: '100%'
    })
}

const FlexTheme = styled.div((props: { attributes: FlexAttributes }) => `

    ${props.attributes.toCSS()}

`);

function Flex(props: ComponentProps<FlexAttributes>) {
    return (
        <FlexTheme {...props}>
            {props.children}
        </FlexTheme>
    )
}


Flex.prototype.Props = FlexAttributes;


export default Flex;