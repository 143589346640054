import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeGroupType, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ContainerAttributes } from "./Container";

@AttributeGroup({ type: AttributeGroupType.State })
export class DeviceContainerStates extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '', exportCssPropertyToWrapper: true })
    desktop: ContainerAttributes = new ContainerAttributes()
    @AttributeProperty({ type: 'Object', cssTarget: '', exportCssPropertyToWrapper: true })
    mobile: ContainerAttributes = new ContainerAttributes()
}


@AttributeGroup({ title: 'DeviceContainer', blockName: 'DeviceContainer', type: AttributeGroupType.State, icon: 'responsive' })
export class DeviceContainerAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '', exportCssPropertyToWrapper: true })
    container: DeviceContainerStates = new DeviceContainerStates()
}

const DeviceContainerTheme = styled.div((props: { attributes: DeviceContainerAttributes }) => `

    ${props.attributes.container.desktop.toCSS()}

    /* & > .pb-container, & [data-type="intellforce/container"] {
        flex-basis: ${props.attributes.container.desktop.flexBasis};
    } */
    
    @media(max-width:800px){
        
        ${props.attributes.container.mobile.toCSS()}

        /* & > .pb-container, & [data-type="intellforce/container"] {
            flex-basis: ${props.attributes.container.mobile.flexBasis};
        } */
    }


`);

function DeviceContainer(props: ComponentProps<DeviceContainerAttributes>) {
    return (
        <DeviceContainerTheme className="pb-container" attributes={props.attributes}>
            {props.children}
        </DeviceContainerTheme>
    )
}


DeviceContainer.prototype.Props = DeviceContainerAttributes;


export default DeviceContainer;