import React from "react";
import ActionButton from "./widgets/ActionButton";
import AsyncImage from "./widgets/AsyncImage";
import Box from "./widgets/Box";
import Column from "./widgets/Column";
import Container from "./widgets/Container";
import DeviceContainer from "./widgets/DeviceContainer";
import DeviceFlex from "./widgets/DeviceFlex";
import Flex from "./widgets/Flex";
import Link from "./widgets/Link";
import LinkButton from "./widgets/LinkButton";
import ListBuilder from "./widgets/ListBuilder";
import Loader from "./widgets/Loader";
import NavLink from "./widgets/NavLink";
import Nav from "./widgets/Navbar";
import Row from "./widgets/Row";
import StateIcon from "./widgets/StateIcon";
import SwitchActionButton from "./widgets/SwitchActionButton";
import Text from "./widgets/Text";
import BRow from "./widgets/layout/BRow";
import BCol from "./widgets/layout/BCol";

export const Core = { BRow, BCol, Loader, ListBuilder, Link, SwitchActionButton, StateIcon, Text, Flex, DeviceFlex, Column, Box, Row, ActionButton, Nav, NavLink, AsyncImage, DeviceContainer, Container, LinkButton };
const cache: any = {};
export async function htmlToElement(element: any, key: string = '0', preventBuild: boolean = false): Promise<any> {

    if (element.nodeName == "#text") return element.wholeText;

    let className: string = element.className?.split(" ")?.pop();
    const module: string = element?.getAttribute('data-module');

    const attributes = JSON.parse(element.getAttribute("data-data") ?? '{}');

    // check if page, replace with async page
    if (className == 'ProductHomepagePage') {
        // this will only initialize the page's children when its route is active
        className = 'AsyncPage';

    }

    const el = await new Promise<any>(async (ok, bad) => {
        const id = (module ? `${module}/` : ``) + className;
        ok(!className || preventBuild ? element.nodeName.toLowerCase() : (!module && (Core as any)[className] ? (Core as any)[className] : (cache[id] || (cache[id] = await import('./widgets/' + id)
            .then((e) => {
                return e.default;
            })
            .catch((e) => {
                return element.nodeName.toLowerCase();
            })))))
    });

    const attributeMap: any = {
        'class': 'className',
    }

    const htmlAttributes = Object.keys((element.attributes || {})).map((key) => element.attributes[key].name).reduce((obj: any, key: string) => {

        if (key == 'style') {
            obj['style'] = style2object(element.getAttribute('style') ?? '');
        } else {
            obj[attributeMap[key] || key] = element.getAttribute(key)?.toString();
        }

        return obj;
    }, {});

    // if async page, pass children as nodes
    if (className == 'AsyncPage') {
        return React.createElement(
            el,
            {
                ...htmlAttributes, "data-data": null, key, attributes: el.prototype && el.prototype.Props ? new el.prototype.Props().fromJSON(attributes) : attributes, nodes: element.childNodes as NodeList
            },
            element.innerHTML);
    }

    const children = element.childNodes as NodeList;

    const compiledChildrenPromises = [];
    for (var i = 0; i < children.length; ++i) {
        const node = children.item(i);
        if (node && node.nodeName != '#comment') {

            compiledChildrenPromises.push(htmlToElement(children[i]!, i.toString()));

        }
    }

    try {
        // console.log(htmlAttributes, JSON.parse(element.getAttribute("data-data")));
        const voidHtmlElements = ['img', 'br'];
        return React.createElement(
            el,
            {
                ...htmlAttributes, "data-data": null, key, attributes: el.prototype && el.prototype.Props ? new el.prototype.Props().fromJSON(attributes) : attributes
            },
            voidHtmlElements.indexOf(el) > -1 ? null : (children?.length > 0 ? await Promise.all(compiledChildrenPromises) : element.innerHTML)
        )
    } catch (e) {
        console.error("Block build failed", element, e);
        return element.wholeText;
    }
}

const camelize = (string: string) => string.replace(/-([a-z])/gi, (s, group) => group.toUpperCase());

// 1ˢᵗ step logic which calls the 2ⁿᵈ step logic
const style2object = (style: string) => style.split(';').filter(s => s.length)
    .reduce((a: any, b) => {
        const keyValue = b.split(':');
        a[camelize(keyValue[0])] = keyValue[1];
        return a;
    }, {});