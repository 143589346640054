import { useContext, useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { PromiseLoader } from "../components/PromiseLoader";
import Utils from "../providers/Utils";
import { Attribute, AttributeGroup, AttributeGroupType, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ConfigContext } from "../providers/Content";

export class AsyncImageState extends Attribute {

    @AttributeProperty({ type: 'Image' })
    placeholder?: string;

    @AttributeProperty({ type: 'Image' })
    image?: string;

    @AttributeProperty({ type: 'String' })
    dynamic?: string;

    @AttributeProperty({ type: 'String' })
    width?: string;

    @AttributeProperty({ type: 'String' })
    height?: string;

    @AttributeProperty({ type: 'Select', selectOptions: [undefined, 'contain', 'cover', 'fill', 'none', 'scale-down'] })
    objectFit: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' | undefined;

    @AttributeProperty({ type: 'String', cssProperty: 'margin' })
    margin?: string;
}

@AttributeGroup({ type: AttributeGroupType.State })
export class AsyncImageStates extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '' })
    desktop: AsyncImageState = new AsyncImageState()
    @AttributeProperty({ type: 'Object', cssTarget: '' })
    mobile: AsyncImageState = new AsyncImageState()
}

@AttributeGroup({ title: 'Fast Image', blockName: 'AsyncImage', type: AttributeGroupType.State })
export class AsyncImageAttributes extends Attribute {

    @AttributeProperty({ type: 'Object' })
    image: AsyncImageStates = new AsyncImageStates()

    @AttributeProperty({ type: 'String' })
    alt?: string;
}

const AsyncImageTheme = styled.div((props: { attributes: AsyncImageAttributes }) => `

    ${props.attributes.toCSS()}

    position: relative;

    img{
        transition: opacity .3s;
    }

`);

function AsyncImage(props: ComponentProps<AsyncImageAttributes>) {

    const content = useContext(ConfigContext);

    const [imagePromise, setImagePromise] = useState<Promise<string | undefined>>();
    const [placeholder, setPlaceholder] = useState<string | undefined>(Utils.isMobile() ? (props.attributes.image.mobile.placeholder ?? props.attributes.image.desktop.placeholder ?? '') : (props.attributes.image.desktop.placeholder ?? ''));
    const [src, setSrc] = useState<string | undefined>(Utils.isMobile() ? (props.attributes.image.mobile.image ?? props.attributes.image.desktop.image ?? '') : (props.attributes.image.desktop.image ?? ''));
    const [done, setDone] = useState(false);



    const getHeight = () => {
        return Utils.isMobile() ? props.attributes.image.mobile.height : props.attributes.image.desktop.height;
    }
    const getWidth = () => {
        return Utils.isMobile() ? props.attributes.image.mobile.width : props.attributes.image.desktop.width;
    }
    const getObjectFit = () => {

        return Utils.isMobile() ? props.attributes.image.mobile.objectFit : props.attributes.image.desktop.objectFit;
    }
    useEffect(() => {
        if (!src) return;
        // setImagePromise(load(src).then((r) => {

        //     return src;
        // }));

    }, [placeholder, src]);

    const load = (src: string) => {
        return new Promise((ok, bad) => {

            const img = new Image();
            img.src = src;
            img.onload = ok;
            img.onerror = bad;
        })
    }

    const getSrc = () => {
        if (Utils.isMobile()) {
            if (props.attributes.image.mobile.dynamic) {
                return content.parseContent(props.attributes.image.mobile.dynamic);
            } else {
                return props.attributes.image.mobile.image ?? props.attributes.image.desktop.image;
            }
        } else {
            if (props.attributes.image.desktop.dynamic) {
                return content.parseContent(props.attributes.image.desktop.dynamic);
            } else {
                return props.attributes.image.desktop.image;
            }
        }

    }

    // get read size
    const width = getSrc()?.split("?s=").pop()?.split("x").map((i) => parseInt(i)).shift();
    const height = getSrc()?.split("?s=").pop()?.split("x").map((i) => parseInt(i)).pop();

    useEffect(() => {
        setPlaceholder(Utils.isMobile() ? (props.attributes.image.mobile.placeholder ?? props.attributes.image.desktop.placeholder) : (props.attributes.image.desktop.placeholder));
        setSrc(Utils.isMobile() ? (props.attributes.image.mobile.image ?? props.attributes.image.desktop.image) : (props.attributes.image.desktop.image));
    }, [props.attributes.image.desktop.image, props.attributes.image.mobile.image, props.attributes.image.desktop.placeholder, props.attributes.image.mobile.placeholder])

    return (
        <AsyncImageTheme  {...props} style={{ width: getWidth(), height: getHeight() }}>
            <img loading="lazy" alt={props.attributes.alt} width={width} onLoad={(e) => {
                if (placeholder && getSrc()) {
                    setImagePromise(load(getSrc() ?? '').then((r) => {
                        return getSrc();
                    }));

                }
            }} height={height} src={placeholder ?? getSrc()} style={{ opacity: done ? 0 : 1, objectFit: getObjectFit(), width: getWidth(), height: getHeight() }} />
            {imagePromise && <PromiseLoader<any> promise={imagePromise} loader={<></>} >
                {res => <img alt={props.attributes.alt} width={width} height={height} style={{ position: "absolute", opacity: done ? 1 : 0, left: 0, top: 0, objectFit: getObjectFit(), width: getWidth(), height: getHeight() }} onLoad={(e) => setDone(true)} src={res} />}
            </PromiseLoader>}
        </AsyncImageTheme>
    )
}


AsyncImage.prototype.Props = AsyncImageAttributes;


export default AsyncImage;