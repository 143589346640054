import { Attribute, AttributeGroup, AttributeProperty } from "../widgets/BaseComponent";

export class BorderAttributes extends Attribute {
    @AttributeProperty({ type: 'Select', selectOptions: ['all', 'top', 'bottom', 'left', 'right'] })
    side: string = 'all';
    @AttributeProperty({ type: 'String', cssProperty: 'border-radius' })
    radius: string = '';
    @AttributeProperty({ type: 'String', cssProperyBuilder: (val, _this) => `border-${(_this.side + '-').replace("all-", "")}width: ${val};` })
    width: string = '';
    @AttributeProperty({ type: 'Select', selectOptions: ['auto', 'solid', 'dotted', 'dashed'], cssProperyBuilder: (val, _this) => `border-${(_this.side + '-').replace("all-", "")}style: ${val};` })
    style: string = '';
    @AttributeProperty({ type: 'Color', cssProperty: 'border-color' })
    color: string = 'inherit';
}