import styled from "styled-components";
import ConfigsProvider, { ConfigContext } from "../providers/Content";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";

@AttributeGroup({ title: 'ListBuilder', blockName: 'ListBuilder' })
export class ListBuilderAttributes extends Attribute {
    @AttributeProperty({ type: 'String' })
    list: string = '';
    @AttributeProperty({ type: 'String' })
    start?: string;
    @AttributeProperty({ type: 'String' })
    end?: string;
}

const ListBuilderTheme = styled.div((props: { attributes: ListBuilderAttributes }) => `

    ${props.attributes.toCSS()}

`);

function ListBuilder(props: ComponentProps<ListBuilderAttributes>) {
    return (
        <ConfigContext.Consumer>
            {content => {
                let list: any[] = content.getContent(props.attributes.list) || [];
                if (typeof list == 'object' && !Array.isArray(list)) {
                    list = [list];
                }

                list = list.slice(parseInt(props.attributes.start ?? '0') ?? 0, parseInt(props.attributes.end ?? `${list.length}`));

                return <>
                    {list?.map((i: any) =>
                        <ConfigsProvider key={i} configJson={{ listItem: i }}><>{props.children}</></ConfigsProvider>
                    )}
                </>
            }}
        </ConfigContext.Consumer>
    )
}


ListBuilder.prototype.Props = ListBuilderAttributes;


export default ListBuilder;