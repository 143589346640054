import styled from "styled-components";
import { BackgroundAttributes } from "../attributes/Background";
import { BorderAttributes } from "../attributes/Border";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { PositionAttributes } from "../attributes/Position";

@AttributeGroup({ title: 'Container', blockName: 'Container' })
export class ContainerAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '' })
    background: BackgroundAttributes = new BackgroundAttributes()

    @AttributeProperty({ type: 'Object', cssTarget: '' })
    border: BorderAttributes = new BorderAttributes()

    @AttributeProperty({ type: 'Object', cssTarget: '' })
    position: PositionAttributes = new PositionAttributes()

    @AttributeProperty({ type: 'String', cssProperty: 'padding' })
    padding?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'margin', exportCssPropertyToWrapper: true })
    margin?: string;

    @AttributeProperty({ type: 'Select', selectOptions: ['flex', 'block', 'inline', 'initial', 'none'], cssProperty: 'display' })
    display?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'max-width' })
    maxWidth?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'height' })
    height?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'width', exportCssPropertyToWrapper: true })
    width?: string;

    @AttributeProperty({ type: 'Select', selectOptions: ['row', 'row-reverse', 'column', 'column-reverse', 'inherit', 'initial', 'revert', 'revert-layer', 'unset'], cssProperty: 'flex-direction' })
    flexDirection?: string;

    @AttributeProperty({ type: 'Select', selectOptions: ['stretch', 'center', 'start', 'end'], cssProperty: 'align-items' })
    alignItems?: string;

    @AttributeProperty({ type: 'Select', selectOptions: ['start', 'stretch', 'center', 'space-between', 'space-around', 'space-evenly', 'end'], cssProperty: 'justify-content' })
    justifyContent?: string;

    @AttributeProperty({ type: 'String', isActive: (_this) => _this.display == 'flex', cssProperty: 'flex-basis', exportCssPropertyToWrapper: true })
    flexBasis?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'flex-grow' })
    flexGrow?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'flex-wrap' })
    flexWrap?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'flex', exportCssPropertyToWrapper: true })
    flex?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'gap' })
    gap?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'box-shadow' })
    boxShadow?: string;

    @AttributeProperty({ type: 'Image', cssTarget: ' ul li', cssProperyBuilder: ((imageUrl: string) => !imageUrl ? '' : `list-style-image: url(${imageUrl});display: list-item;`) })
    listStyle?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'min-height' })
    minHeight: string = '';
    @AttributeProperty({ type: 'String', cssProperty: 'min-width' })
    minWidth?: string;

    @AttributeProperty({ type: 'Select', selectOptions: ['auto', 'center', 'left', 'right'], cssProperty: 'text-align' })
    textAlign?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'z-index' })
    zIndex?: string;

    @AttributeProperty({ type: 'Select', cssProperty: 'overflow', selectOptions: ['scroll', 'hidden', 'auto', 'visible'] })
    overflow?: string;
}

const ContainerTheme = styled.div((props: { attributes: ContainerAttributes }) => `
   
   &.pb-container{
        ${props.attributes.toCSS()}
   }

    
`);

function Container(props: ComponentProps<ContainerAttributes>) {

    return (
        <ContainerTheme {...props} className={`pb-container ${props.className}`} attributes={props.attributes}>{props.children}</ContainerTheme>
    )

}

Container.prototype.Props = ContainerAttributes;

export default Container;