import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ButtonStateAttributes } from "./LinkButton";
import { ContainerAttributes } from "./Container";
import React, { useEffect, useState } from "react";
import ConfigsProvider from "../providers/Content";
import { useLocation } from "react-router-dom";
import { LinkTheme } from "./NavLink";

@AttributeGroup({ title: 'Navigation', blockName: 'Navbar' })
export class NavbarAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '', exportCssPropertyToWrapper: true })
    container: ContainerAttributes = new ContainerAttributes()

    @AttributeProperty({ title: 'Sub Menu container', type: 'Object', cssTarget: ' li .sub-menu' })
    subMenuContainer: ContainerAttributes = new ContainerAttributes()

    @AttributeProperty({ type: 'Object', cssTarget: ' li a.nav-link' })
    navLink: LinkTheme = new LinkTheme();

    @AttributeProperty({ type: 'Object', cssTarget: ' li a.nav-link[role=button], button' })
    navButton: LinkTheme = new LinkTheme()

    @AttributeProperty({ type: 'Boolean', cssProperyBuilder: (val) => val ? `position: sticky;` : '' })
    sticky: boolean = true;

    @AttributeProperty({ type: 'Object', cssTarget: ' a.nav-link .icon' })
    iconContainer: ContainerAttributes = new ContainerAttributes().fromJSON({ width: '20px', height: '20px', margin: '0 10px 0 0' })

    @AttributeProperty({ type: 'Boolean' })
    displayActiveLinkSubMenu: boolean = false;

    @AttributeProperty({ type: 'Boolean' })
    fixedSubmenu?: boolean;

    @AttributeProperty({ type: 'Boolean' })
    isDrawerMenu: boolean = false;
}

export const DrawerNavContext = React.createContext<{ isOpen: boolean, setIsOpen: (isOpen: boolean) => void }>({ isOpen: false, setIsOpen: (s) => { } });

export const DrawerNavProvider = (props: any) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    let location = useLocation();

    // watch route changes
    useEffect(() => {
        // hide login on navigation
        setIsOpen(false);
    }, [location]);

    return <DrawerNavContext.Provider value={{ isOpen, setIsOpen }} ><ConfigsProvider configJson={{ drawer: { isOpen: isOpen, open: () => setIsOpen(true), close: () => setIsOpen(false) } }} >
        {props.children}
    </ConfigsProvider >
    </DrawerNavContext.Provider>
}

const NavbarTheme = styled.nav((props: { attributes: NavbarAttributes, isOpen?: boolean }) => `

    a.nav-link{
        display: flex;
    }

    
    
    li:not(:hover){
        
        > .sub-menu{
            display: none;
        }
    }

    li{
        position: relative;

       

        
        
    }

    &.auto-display-active-sub-menu{

        li.active{
            > .sub-menu{
                display: block !important;
            }
        }
    }

    &.fixed-sub-menu{
        li{
            > .sub-menu{
                display: block !important;
            }
        }
    }

    ${props.attributes.toCSS()}

    @media(max-width:800px){

        ${props.attributes.isDrawerMenu && `
            
            position: fixed !important;
            left: -100%  !important;
            z-index: 10000;
            transition: left .35s;

            &.isOpen{
                left: 0 !important;
            }
        `}
    }

`);

function NavigationBar(props: ComponentProps<NavbarAttributes>) {

    return (
        <DrawerNavContext.Consumer>{({ isOpen }) =>
            <NavbarTheme isOpen={isOpen} {...props} className={`${props.attributes.displayActiveLinkSubMenu && 'auto-display-active-sub-menu'} ${(props.attributes.fixedSubmenu ?? false) && 'fixed-sub-menu'} ${isOpen ? 'isOpen' : ''}`} >
                {props.children}
            </NavbarTheme>
        }</DrawerNavContext.Consumer>
    )
}


NavigationBar.prototype.Props = NavbarAttributes;


export default NavigationBar;