import React, { ReactNode } from "react";

type BaseProps = {
    attributes?: any, children?: ReactNode,
    [key: string]: any
}

export class Attribute {

    fromJSON(json: Partial<Omit<typeof this, "fromJSON">>): typeof this {
        const _this: any = this;
        Object.keys(json).forEach((key, i) => {
            // if property is instance of Attribute, parse json
            if (_this[key] instanceof Attribute) {

                Object.assign(this, { [key]: _this[key].fromJSON((json as any)[key]) });
            } else {
                Object.assign(this, { [key]: (json as any)[key] });
            }
        })
        return this;
    }

    /**
     * compile all properties and nested objects marked for css to a css string
     */
    toCSS(): string {
        const _this: any = this;
        return Object.keys(_this).map((key: string, i) => {
            const attributeMeta: { [key: string]: AttributeMeta } = this.constructor.prototype.attributes;
            const cssProperty = attributeMeta && attributeMeta[key] && attributeMeta[key].cssProperty;
            // if (!cssProperty) return '';

            const css: string = _this[key] instanceof Attribute ?
                (_this[key] as Attribute).toCSS()
                :
                (attributeMeta && attributeMeta[key] && attributeMeta[key].cssProperyBuilder ? `${attributeMeta[key].cssProperyBuilder!(_this[key], _this)};` : (cssProperty && _this[key] ? `${cssProperty}:${_this[key]}${attributeMeta[key].cssValuePostfix ?? ''};` : ''));
            const cssTarget = attributeMeta && attributeMeta[key] && attributeMeta[key].cssTarget;
            return cssTarget && css ? `
                &${(cssTarget || '')}{
                \t${css}
                }
            ` : `${css}`;
        }).join("\n");
    }

    extractWrapperCssProperties(): any {
        const _this: any = this;
        return Object.keys(_this).reduce((res: any, key: string, i) => {
            const attributeMeta: { [key: string]: AttributeMeta } = _this.constructor.prototype.attributes;
            const cssProperty = attributeMeta && attributeMeta[key] && attributeMeta[key].cssProperty;
            // if (!cssProperty) return '';


            if (_this[key] instanceof Attribute && attributeMeta[key]?.exportCssPropertyToWrapper)
                return { ...res, ...(_this[key] as Attribute).extractWrapperCssProperties() };

            else if (attributeMeta[key]?.exportCssPropertyToWrapper && cssProperty) {
                res[cssProperty!] = _this[key];
            }

            return res;

            // (attributeMeta && attributeMeta[key] && attributeMeta[key].cssProperyBuilder ? `${attributeMeta[key].cssProperyBuilder!(_this[key], _this)};` : (cssProperty ? `${cssProperty}:${_this[key]}${attributeMeta[key].cssValuePostfix ?? ''};` : ''));
            // const cssTarget = attributeMeta && attributeMeta[key] && attributeMeta[key].cssTarget;
            // return cssTarget || css ? `
            //     &${(cssTarget || '')}{
            //     \t${css}
            //     }
            // ` : '';
        }, {});
    }
}

export abstract class BaseComponent<T extends BaseProps = BaseProps, S = {}, F = {}> extends React.Component<T, S, F> {

    static attributes: { [key: string]: AttributeMeta } = {};
}

export interface AttributeMeta {
    type: string
    title?: string
    input?: string
    default?: any
    states?: string[]
    arrayType?: any
    arrayLabelProperty?: string
    cssTarget?: string
    cssProperty?: string
    cssValuePostfix?: string
    cssProperyBuilder?: (val: any, _this: any) => string
    selectOptions?: any[]
    isActive?: (_this: any) => boolean
    exportCssPropertyToWrapper?: boolean,
    disable?: string[]
}

export enum AttributeGroupType {
    State = 'state',
    List = 'array',
}

export class AttributeType {
    static readonly String = (config: { title?: string, default?: string }): string => config.default ?? '';
    static readonly Number = (config: { title?: string, default?: any }): AttributeMeta => ({ type: 'number', input: 'text', ...config });
    static readonly Boolean = (config: { title?: string, default?: string }): AttributeMeta => ({ type: 'boolean', input: 'checkbox', ...config });
    static readonly Color = (config: { title?: string, default?: string }): AttributeMeta => ({ type: 'string', input: 'color', ...config });
    static readonly Image = (config: { title?: string, default?: string }): AttributeMeta => ({ type: 'string', input: 'image', ...config });
    static readonly Array = (config: { query: AttributeControls<any>, title?: string, default?: any[] }): AttributeMeta => ({ type: 'array', input: 'object', default: [], ...config });
    static readonly Object = (config: { query: AttributeControls<any>, title?: string, default?: any }): AttributeMeta => ({ type: 'object', input: 'object', default: {}, ...config });
    static readonly State = (config: { query: AttributeControls<any>, title?: string, default?: any, states: string[] }): AttributeMeta => ({ type: 'state', input: 'object', default: {}, ...config });
}

export function AttributeProperty<T extends typeof BaseComponent>(type: AttributeMeta) {
    function f(target: T, propertyKey: string, descriptor: PropertyDescriptor) {

        (target.constructor.prototype as T).attributes = (target.constructor.prototype as T).attributes || {};
        (target.constructor.prototype as T).attributes[propertyKey] = type;

    }

    return f as any;
}

export type AttributeGroupMeta = {
    title?: string
    blockName?: string,
    icon?: Element | string | JSX.Element,
    blockNamePrefix?: string,
    example?: any,
    type?: AttributeGroupType,
    module?: string
}

export function AttributeGroup(meta: AttributeGroupMeta) {

    return function <T extends { new(...args: any[]): {} }>(constructor: T) {
        constructor.prototype.meta = meta;
        return constructor;
    }
}

export const withForm = (someBooleanFlag: boolean) => {
    return (constructor: Function) => {
        console.log(constructor);

    }
}

export type AttributeControls<T> = { [key in keyof T]: AttributeMeta | AttributeControls<any> }

export type ComponentProps<T = {}, P = {}> = P & {
    attributes: T,
    onClick?: any,
    className?: string,
    children?: JSX.Element|JSX.Element[]
}
