import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ButtonStateAttributes } from "./LinkButton";
import { useContext } from "react";
import { ConfigContext } from "../providers/Content";
import { LinkTheme as _LinkTheme } from "./NavLink";

@AttributeGroup({ title: 'Link', blockName: 'Link' })
export class LinkAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', cssTarget: '' })
    navLink: _LinkTheme = new _LinkTheme();

    @AttributeProperty({ type: 'String' })
    href: string = '';
}

const LinkTheme = styled.a((props: { attributes: LinkAttributes }) => `

    ${props.attributes.toCSS()}

`);

function Link(props: ComponentProps<LinkAttributes>) {

    const content = useContext(ConfigContext);

    return (
        <LinkTheme href={content.parseContent(props.attributes.href)} {...props}>
            {props.children}
        </LinkTheme>
    )
}


Link.prototype.Props = LinkAttributes;


export default Link;