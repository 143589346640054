class _Utils {

    isMobile(): boolean {
        // console.log((window as any).innerWidth);
        // console.log(document.body.clientWidth);

        return (window as any).innerWidth < 800;
    }
}
const Utils = new _Utils();
export default Utils;

export function getCookie(key: string) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

export function setCookie(name: string, value: string, expirationMilliseconds: number) {
    var expires = "";
    if (expirationMilliseconds) {
        var date = new Date();
        date.setTime(date.getTime() + (expirationMilliseconds * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const isEditor = (): boolean => (window as any).wp?.components;

export const onWindowLoadFinished = (): Promise<void> => {

    return new Promise((ok, bad) => {

        if (document.readyState != "loading") {
            ok();
        } else {
            window.addEventListener('DOMContentLoaded', () => {
                ok();
            })
        }
    })
}

export function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}