import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "../BaseComponent";
import { Container, Row, Col, Column, ColProps } from 'styled-bootstrap-grid';
import { red } from "@mui/material/colors";
import { media } from 'styled-bootstrap-grid';
import { BackgroundAttributes } from "../../attributes/Background";
import { BorderAttributes } from "../../attributes/Border";

export class BColProps extends Attribute {
    @AttributeProperty({ type: 'Object', cssTarget: '' })
    background: BackgroundAttributes = new BackgroundAttributes()
    @AttributeProperty({ type: 'Object', cssTarget: '' })
    border: BorderAttributes = new BorderAttributes()
    @AttributeProperty({ type: 'String' })
    span?: Column | undefined;
    @AttributeProperty({ type: 'String' })
    offset?: string;
    @AttributeProperty({ type: 'String' })
    order?: "first" | "last" | number;
    @AttributeProperty({ type: 'Select', selectOptions: ['start', 'end', 'center', 'baseline', 'stretch'] })
    alignSelf?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
    @AttributeProperty({ type: 'String' })
    flexGrow?: string;
    @AttributeProperty({ type: 'String' })
    padding: string = '0';
}

@AttributeGroup({ title: 'BCol', blockName: 'BCol', module: 'layout' })
export class BColAttributes extends Attribute {

    @AttributeProperty({ type: 'Object', title: 'Default' })
    sm: BColProps = new BColProps();
    @AttributeProperty({ type: 'Object', title: 'Tablet' })
    md: BColProps = new BColProps();
    @AttributeProperty({ type: 'Object', title: 'Desktop' })
    lg: BColProps = new BColProps();
}

const BColTheme = styled<ColProps & ComponentProps<BColAttributes> & any>(Col)`
    
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    width: auto;

    padding: ${(props: ColProps & ComponentProps<BColAttributes>) => props.attributes.sm.padding};
    flex-grow: ${(props: ColProps & ComponentProps<BColAttributes>) => props.attributes.sm.flexGrow};
    ${(props: ColProps & ComponentProps<BColAttributes> & any) => props.attributes.sm.background.toCSS()};

    ${media.md`
        padding: ${(props: ColProps & ComponentProps<BColAttributes> & any) => props.attributes.md.padding};
        flex-grow: ${(props: ColProps & ComponentProps<BColAttributes> & any) => props.attributes.md.flexGrow};
        ${(props: ColProps & ComponentProps<BColAttributes> & any) => props.attributes.md.background.toCSS()};
    `}
    ${media.lg`
        padding: ${(props: ColProps & ComponentProps<BColAttributes> & any) => props.attributes.lg.padding};
        flex-grow: ${(props: ColProps & ComponentProps<BColAttributes> & any) => props.attributes.lg.flexGrow};
        ${(props: ColProps & ComponentProps<BColAttributes> & any) => props.attributes.lg.background.toCSS()};
    `}

`;

function BCol(props: ComponentProps<BColAttributes>) {
    return (
        <BColTheme {...props}

            {...["sm", "md", "lg"].reduce((res: any, size: string) => {
                res[`${size}`] = ((props.attributes as any)[`${size}`] as BColProps).span;
                res[`${size}Offset`] = ((props.attributes as any)[`${size}`] as BColProps).offset;
                res[`${size}Order`] = ((props.attributes as any)[`${size}`] as BColProps).order;
                return res;
            }, {})}
        >
            {props.children}
        </BColTheme>
    )
}


BCol.prototype.Props = BColAttributes;


export default BCol;