import React from 'react';
import ReactDOM from 'react-dom/client';
import { htmlToElement } from './Core';
// import './index.css';
// import * as Core from './Core';
// import reportWebVitals from './reportWebVitals';

const e = document.getElementById('root') as HTMLElement;
const splash = document.getElementById('splash') as HTMLElement;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// import('./widgets/' + window.location.hash.replace("#", "")).then((c) => {

htmlToElement(e).then((_e) => {
  root.render(
    _e
  );
  setTimeout(() => {
    splash.style.display = "block";
  }, 10)
})