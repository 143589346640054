import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { FontTheme } from "../attributes/FontTheme";
import { useContext } from "react";
import { ConfigContext } from "../providers/Content";
import { useParams } from "react-router-dom";

@AttributeGroup({ title: 'Text', blockName: 'Text' })
export class TextAttributes extends Attribute {

    @AttributeProperty({ type: 'Select', selectOptions: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'label'] })
    type: 'h1' | 'h2' | 'p' = 'p';

    @AttributeProperty({ type: 'Text' })
    content?: string;

    @AttributeProperty({ type: 'Object', cssTarget: '' })
    font: FontTheme = new FontTheme();

    @AttributeProperty({ type: 'String', cssProperty: 'height' })
    height?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'text-overflow' })
    overflow?: string;

    @AttributeProperty({ type: 'String', cssProperty: 'white-space' })
    whiteSpace?: string;

    @AttributeProperty({ type: 'String', cssProperty: '-webkit-line-clamp' })
    maxLines?: string;
}

const TextTheme = styled.div((props: { attributes: TextAttributes }) => `

    &.${props.attributes.type}{

        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;

        ${props.attributes.toCSS()}
    }

`);

function Text(props: ComponentProps<TextAttributes>) {

    //     const content = useContext(ConfigContext);
    // console.log(content.configs);
    // const routeParams = useParams<any>();
    // console.log('Params', routeParams);

    const type = props.attributes.type;
    const { children, ...otherProps } = props;

    const htmlDecode = (content: string): string => {
        let e = document.createElement('div');
        e.innerHTML = content;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue ?? "";
    }

    return (
        <ConfigContext.Consumer >
            {content => {
                // console.log(content.parseContent(props.attributes.content ?? ''));

                return <TextTheme as={type} dangerouslySetInnerHTML={{ __html: `${content.parseContent(props.attributes.content ?? '').replaceAll("&lt;","<")}` }} {...otherProps} className={`${type} ${(otherProps as any).className}`} />
            }}
        </ConfigContext.Consumer>
    )
}


Text.prototype.Props = TextAttributes;


export default Text;