import styled from "styled-components";
import { Attribute, AttributeGroup, AttributeProperty, ComponentProps } from "./BaseComponent";
import { ContainerAttributes } from "./Container";
import { DeviceContainerAttributes } from "./DeviceContainer";
import { DeviceContainerStates } from "./DeviceContainer";

@AttributeGroup({ title: 'Row', blockName: 'Row' })
export class RowAttributes extends Attribute {
    @AttributeProperty({ type: 'Object', title: 'Row', cssTarget: '', disable: ['display', 'flexDirection', 'flex', 'flexBasis', 'listStyle', 'zIndex', 'textAlign', 'position', 'flexGrow', 'padding'] })
    container: ContainerAttributes = new ContainerAttributes().fromJSON({
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    })
}

const RowTheme = styled.div((props: { attributes: RowAttributes }) => `

    ${props.attributes.toCSS()}

`);

function Row(props: ComponentProps<RowAttributes>) {
    return (
        <RowTheme {...props}>
            {props.children}
        </RowTheme>
    )
}


Row.prototype.Props = RowAttributes;


export default Row;